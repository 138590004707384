import React from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Button, Divider, Loader } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router';

import PhoneInput from '../../components/PhoneInput';
import TextInput from '../../components/TextInput';
import Dropdown from '../../components/Dropdown';
import FieldInput from '../../components/FieldInput';
import Responsive from '../../components/Responsive';
import Modal from '../../components/Modal';
import ColoredCheckbox from '../../components/ColoredCheckbox';
import TermsAndPrivacy from '../../components/TermsAndPrivacy';
import Footer from '../Free/Footer';

import { __ } from '../../i18n';
import parse from '../../lib/parse';

const errorMap = {
  email: __('Email address is not valid: check domain name and if it contains @ and .'),
  phone: __('Phone number is not valid: check area code and number length'),
  fullname: __('Fullname is required'),
};

const styles = {
  footer: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'white',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  button: {
    borderRadius: '40px',
    paddingTop: '15px',
    paddingBottom: '15px'
  },
  mobileButton: {
    borderRadius: '40px',
    paddingTop: '20px',
    paddingBottom: '20px'
  },
  term: {
    display: 'flex',
    alignContent: 'flex-end',
    justifyContent: 'center',
  }
};

const simulationOrganizationId = (env) => {
  let id = 14;

  if (['stage', 'internal'].includes(env)) {
    id = 1961;
  } else if (['production', 'beta'].includes(env)) {
    id = 3594;
  }

  return id;
};

@graphql(gql`mutation createEntity($createEntityMutation: CreateEntityInput!) {
  createEntity(input: $createEntityMutation) {
    entity {
      id: dbId
    }
    clientMutationId
  }
}`)

@graphql(gql`mutation findUser($email: String, $phone: String, $invite: Boolean, $isNewCode: Boolean) {
  findUser(email: $email, phone: $phone, invite: $invite, source: WEB, isNewCode: $isNewCode) {
    __typename
    ... on User {
        fullname
        email
        phone
        hasPassword
        shouldAskForPassword
      }
      ... on PublicAddress {
        address
      }
  }
}`, { name: 'findUser' },
)
@inject('store', 'client') @observer
export default class SimulationProspect extends Responsive {
  constructor(props) {
    super(props);

    this.state = {
      type: null,
      email: '',
      errors: [],
      fullname: '',
      loading: 'page',
      phone: '',
      phoneFormatted: '',
      checked: true,
      wppPermission: false
    };
  }

  UNSAFE_componentWillMount() {
    const { simulation, prospect } = this.props.store;

    if (simulation.entity) {
      this.props.router.push('/simulation/code');
    }

    if (!simulation.user) {
      simulation.user = {
        user: {}
      };
    }

    const { fullname, phone, email, phoneFormatted, type, wppPermission } = simulation.user || prospect.user;
    this.setState({
      fullname: fullname || '',
      phone: phone || '',
      phoneFormatted: phoneFormatted || '',
      email: email || '',
      type: type || null,
      wppPermission: wppPermission || false
    });
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  handleSubmit = (e) => {
    if (!navigator.onLine) {
      this.props.store.snackbar = { active: true, message: __('No connection available'), dismissAfter: 4000 };
      return null;
    }
    this.setState({ loading: true });
    e.preventDefault();
    this.validate();
  }

  validate = () => {
    const { fullname, email, phone, } = this.state;
    const validFullname = !!fullname.trim();
    const validEmail = !!email && parse.validateEmail(email);
    const validPhone = !!phone && parse.validatePhone('', phone);

    const valid = validEmail && validFullname && validPhone;
    if (valid) {
      this.setState({ errors: [] });
      this.submit();
    } else {
      const errors = [];
      if (!validFullname) errors.push('fullname');
      if (!validEmail) errors.push('email');
      if (!validPhone) errors.push('phone');
      this.setState({ errors });
      this.setState({ loading: false });
    }
  }

  handleLogin = (type, data) => {
    const { store, router } = this.props;

    if (type && data) {
      const { fullname, email, phone, hasPassword, shouldAskForPassword } = data.data.findUser;
      store.auth.user = { fullname, email, phone, hasPassword, shouldAskForPassword, methodType: 'trial-login' };
      store.auth.type = type;
      store.auth.phone = data.data.findUser.phone;
      store.auth.email = data.data.findUser.email;

      router.push('/auth/login');
    } else {
      router.push('/auth');
    }
  }

  submit = async () => {
    const { store, client, router, mutate } = this.props;
    const { email, phone, fullname, checked, wppPermission, phoneFormatted } = this.state;
    const params = {};

    params.phone = phone.replace(/[^0-9]+/g, '');
    params.email = email;

    this.props.findUser({
      variables: {
        phone: params.phone,
        invite: true,
        isNewCode: true
      }
    }).then((data) => {
      if (data.data.findUser.__typename === 'User') {
        this.handleLogin('phone', data);
      } else {
        throw new Error('Next step');
      }
    }).catch((e) => {
      this.props.findUser({
        variables: {
          email: params.email,
          invite: true,
          isNewCode: true
        }
      }).then((data) => {
        if (data.data.findUser.__typename === 'User') {
          this.handleLogin('email', data);
        } else {
          throw new Error('Next step');
        }
      }).catch(async (e) => {
        store.simulation.user = {
          ...store.simulation.user,
          fullname,
          email,
          phone: phone.replace(/[^0-9]+/g, ''),
          phoneFormatted,
          checked,
          wppPermission
        };
        store.simulation.organization = { fullname, type: 'phone' };
        store.simulation.entity = {
          organizationId: simulationOrganizationId(store.app.env),
          fullname: store.simulation.user.fullname,
          eid: `${(new Date()).getTime()}+${email}`,
          type: 'STUDENT',
          defaultFeatures: [
            'MESSAGE'
          ],
          addresses: [
            { address: store.simulation.user.email, type: 'EMAIL' },
            { address: store.simulation.user.phone, type: 'PHONE' }
          ]
        };
        const createEntity = await mutate({
          variables: {
            createEntityMutation: store.simulation.entity
          }
        });
        store.simulation.entity.id = createEntity.data.createEntity.entity.id;
        await client.mutate({
          mutation: gql`mutation findUser($phone: String, $invite: Boolean, $isNewCode: Boolean) {
              findUser(phone: $phone, invite: $invite, source: WEB, isNewCode: $isNewCode) {
                __typename
              }
            }`,
          variables: {
            phone: store.simulation.user.phone,
            invite: true,
            isNewCode: true
          },
          options: {
            fetchPolicy: 'network-only'
          }
        });
        router.push('/simulation/code');

        this.setState({ loading: false });
      });
    });
  }

  renderForm = () => {
    const { errors, fullname, email, phone, wppPermission } = this.state;
    return (
      <div style={{ paddingBottom: '10px' }}>
        <Form>
          <div style={{ display: 'flex', flexFlow: 'column' }}>
            <p style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 28, color: '#1D1D1D', marginBottom: 15, marginTop: 0 }}>{__('Create a simulation')}</p>
            <p style={{ marginBottom: 40, fontSize: 15, color: '#6C6C6C' }}>{__('Before accessing ClassApp, we need some information about you.')}</p>
          </div>
          <TextInput
            name="fullname"
            autoFocus
            value={fullname}
            placeholder={__('Type your name here...')}
            label={__('Full Name')}
            error={errors.indexOf('fullname') !== -1 && errorMap.fullname}
            onChange={(e, data) => this.setState({ fullname: data.value })}
          />
          <FieldInput
            name="phone"
            label={__('Phone')}
            placeholder={__('Area Code + Phone')}
            error={errors.indexOf('phone') !== -1 && errorMap.phone}
            control={PhoneInput}
            defaultValue={phone}
            onChange={value => this.setState({ phone: value, phoneFormatted: value })}
          />
          <TextInput
            name="email"
            type="email"
            label={__('Email')}
            placeholder={__('your@email.com')}
            value={email}
            icon="envelope"
            iconPosition="left"
            error={errors.indexOf('email') !== -1 && errorMap.email}
            onChange={(e, data) => this.setState({ email: data.value })}
          />
          <div style={{ marginTop: '30px', display: 'flex' }}>
            <ColoredCheckbox
              radio
              radioChecked
              data-id="terms"
              data-action="check"
              checked={wppPermission}
              onClick={() => this.setState({ wppPermission: !this.state.wppPermission })}
              style={{ fontSize: '14px', fontWeight: 'bold', marginRight: '10px' }}
            />
            <p>{__('I agree to be contacted via WhatsApp')}</p>
          </div>
        </Form>
      </div>
    );
  }

  goToLoginPage = () => {
    const { router } = this.props;
    router.push('/');
  }

  renderFooter = () => {
    const { loading, email, phone, fullname } = this.state;
    const disabled = !email || !phone || !fullname;
    return (
      <Footer
        before={(<div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', flexDirection: 'row', marginBottom: '20px' }}>
          <span>
            {__('By continuing you agree to our ')}
            <Link target="_blank" to={'/terms?type=student'}> {__('Terms and Privacy')} </Link>
          </span>
        </div>)}
        loading={loading}
        primary={{ label: __('Next step'), handleClick: this.handleSubmit, disabled }}
        secondary={{ label: __('I`m already registered'), handleClick: () => this.goToLoginPage() }}
      />
    );
  }

  render() {
    const { loading } = this.state;

    if (loading === 'page') {
      return (<div id="DemoProspect">
        <Divider hidden />
        <div style={{ width: '100%', display: 'inline-block', textAlign: 'center' }}>
          <Loader active inline />
        </div>
        {this.props.children}
      </div>
      );
    }

    return (
      <div id="DemoProspect" style={{ maxWidth: '460px', marginLeft: 'auto', marginRight: 'auto' }}>
        {this.renderForm()}
        {this.renderFooter()}
      </div>
    );
  }
}
