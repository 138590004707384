import React from 'react';
import { inject, observer } from 'mobx-react';
import { Form, Button, Popup, Divider, Container } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { execute, makePromise } from 'apollo-link';
import cookie from 'react-cookie';

import i18n, { __ } from '../../i18n';
import * as utils from '../../utils';

import Timer from '../../components/Timer';
import TextInput from '../../components/TextInput';
import Responsive from '../../components/Responsive';

const styles = {
  button: {
    borderRadius: '40px',
    paddingTop: '15px',
    paddingBottom: '15px',
    backgroundColor: '#FFF',
    color: '#212529',
  },
  mobileButton: {
    borderRadius: '40px',
    paddingTop: '20px',
    paddingBottom: '20px',
    backgroundColor: '#FFF',
    color: '#212529',
  },
  primary: {
    backgroundColor: '#0069FF',
    color: '#FFF'
  }
};

@inject('store', 'api', 'client') @observer
export default class SimulationCodeChecker extends Responsive {
  state = {
    error: false,
    code: '',
    waiting: false
  };

  componentDidMount() {
    const { organization } = this.props.store.simulation;

    if (!organization) this.props.router.push('/simulation');
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.validate();
  }

  validate = () => {
    const { code } = this.state;
    const valid = code && code.length === 6;

    if (!valid) {
      this.setState({ error: true });
    } else {
      this.setState({ error: false }, () => {
        this.submit();
      });
    }
  }

  submit = async () => {
    this.setState({ loading: true });

    const { code } = this.state;
    const { client, store, router } = this.props;
    const { user } = store.simulation;

    try {
      const findAddress = await client.mutate({
        mutation: gql`mutation CodeSignupQuery($code: String!, $address: String!) {
          findAddress(code:$code, address: $address){
            id: dbId
            code
            type
          }
        }`,
        variables: {
          code,
          address: user.phone
        }
      });

      if (findAddress.errors) {
        store.snackbar = { active: true, message: utils.handleError(findAddress.errors[0], 'codesignup'), success: false };
      } else {
        const params = { language: 'pt' };

        if (typeof window !== 'undefined' && window.navigator && window.navigator.language) {
          params.language = window.navigator.language.substring(0, 2) === 'en' ? 'en' : 'pt';
        }

        const createUser = await client.mutate({
          mutation: gql`mutation createUser($createUserMutation: CreateUserInput!) {
              createUser(input: $createUserMutation) {
                user {
                  oauthProvider {
                    accessToken
                    refreshToken
                  }
                }
                clientMutationId
              }
            }`,
          variables: {
            createUserMutation: {
              ...params,
              phone: user.phone,
              email: user.email,
              fullname: user.fullname,
              addresses: [{ code }],
              tzOffset: -(new Date().getTimezoneOffset()),
              isNewCode: true
            }
          }
        });

        const { oauthProvider } = createUser.data.createUser.user;
        const { accessToken, refreshToken } = oauthProvider;

        cookie.save('access_token', accessToken, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 7 });
        cookie.save('refresh_token', refreshToken, { path: '/', domain: store.app.host.replace(/www|beta|https:\/\/|:5400/g, ''), maxAge: 60 * 60 * 24 * 7 });
        this.props.store.access_token = accessToken;
        this.props.store.refresh_token = refreshToken;
        this.props.api.access_token = accessToken;

        router.push('/simulation/finish');
      }
    } catch (err) {
      console.log(err);
      this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0], 'codesignup'), success: false, dismissAfter: 6000 };
    } finally {
      this.setState({ loading: false });
    }
  };

  resendCode = () => {
    const { store, client } = this.props;

    client.mutate({
      mutation: gql`mutation findUser($phone: String) {
      findUser(phone: $phone, invite: true, source: WEB, isNewCode: true) {
        __typename
      }
    }`,
      variables: {
        phone: store.simulation.user.phone
      }
    }).then(() => {
      this.setState({ waiting: true });
    })
      .catch((err) => { this.props.store.snackbar = { active: true, message: utils.handleError(err.graphQLErrors[0]), success: false }; });
  };

  renderForm = () => {
    const { store } = this.props;
    const { code, error } = this.state;

    return (
      <div style={{ paddingBottom: 24 }}>
        <Form>
          <div style={{ display: 'flex', flexFlow: 'column' }}>
            <p style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: 28, color: '#1D1D1D', marginBottom: 15, marginTop: 0 }}>{__('Validate your phone')}</p>
            <p style={{ marginBottom: 40, fontSize: 15, color: '#6C6C6C' }}>
              {__('We sent you a SMS with a 6-digit access code for the number')}
              <br />
              {store.simulation.user && <span style={{ color: '#1D1D1D', fontWeight: 'bold' }}>{store.simulation.user.phoneFormatted}</span>}
            </p>
          </div>
          <TextInput
            name="code"
            placeholder="* * * * * *"
            label={__('Enter the 6-digit code below to validate it:')}
            value={code}
            maxLength={6}
            onChange={(e, data) => this.setState({ code: data.value })}
            error={error && __('The code must have 6 digits')}
            style={{ height: 50 }}
            className="code-checker"
          />
          {this.state.waiting && <Container style={{ border: '1px solid #e9ecef', borderRadius: '5px', padding: '16px' }}>
            {__('We sent you an SMS with a link. Check your messages for instructions')}
          </Container>}
        </Form>
      </div>
    );
  }

  renderFooter = () => {
    const { waiting } = this.state;

    if (this.isMobile()) {
      return (
        <div>
          <Button type="submit" fluid style={{ ...styles.mobileButton, ...styles.primary, marginBottom: 12 }} onClick={this.handleSubmit}>
            <span style={{ fontSize: '15px' }}>{__('Next')}</span>
          </Button>
          <Button fluid style={{ ...styles.mobileButton }} disabled={waiting} onClick={this.resendCode}>
            <span style={{ fontSize: '15px', color: '#0069FF' }}>
              {waiting ? <Timer
                interval={30}
                content={__('Wait')}
                textColor="#212529"
                onFinishTimer={() => this.setState({ waiting: false })}
              />
                : __('I didn\'t receive the code')}
            </span>
          </Button>
        </div>);
    }

    return (
      <div>
        <Button type="submit" fluid style={{ ...styles.button, ...styles.primary, marginBottom: 12 }} onClick={this.handleSubmit}>
          <span style={{ fontSize: '15px' }}>{__('Next')}</span>
        </Button>
        <Button fluid style={{ ...styles.button }} disabled={waiting} onClick={this.resendCode}>
          <span style={{ fontSize: '15px', color: '#0069FF' }}>
            {waiting ? <Timer
              interval={30}
              content={__('Wait')}
              textColor="#212529"
              onFinishTimer={() => this.setState({ waiting: false })}
            />
              : __('I didn\'t receive the code')}
          </span>
        </Button>
      </div>);
  }

  render() {
    return (
      <div id="DemoCodeChecker" style={{ maxWidth: '460px', marginLeft: 'auto', marginRight: 'auto' }}>
        {this.renderForm()}
        {this.renderFooter()}
      </div>
    );
  }
}
