import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Grid, Sidebar, Label, Image, Icon } from 'semantic-ui-react';
import Steps from '../../components/Steps';

import Snackbar from '../../components/Snackbar';
import NoJavascript from '../../components/NoJavascript';
import Responsive from '../../components/Responsive';

import * as utils from '../../utils';
import { __ } from '../../i18n';

const styles = {
  icon: {
    margin: 'auto',
    fontSize: '1em',
    color: 'white',
    fontWeight: 'bold',
  },
  nextIcon: {
    margin: 'auto',
    fontSize: '1em',
    color: '#495057',
    fontWeight: 'bold',
  },
  label: {
    color: '#0069FF', //'#228be6',
    fontWeight: 'bold'
  },
  footer: {
    position: 'fixed',
    bottom: '0px',
    left: '50%',
    borderTop: '1px solid rgba(0, 0, 0, .1)',
    padding: '20px',
    display: 'flex',
    width: '100%',
    marginLeft: '-50%',
    backgroundColor: 'white',
    justifyContent: 'center'
  }
};

@inject('store') @observer
export default class FreeNode extends Responsive {
  static fetchData({ store }) {
    store.app.title = __('Sign Up');
  }

  constructor(props) {
    super(props);

    this.state = {
      pageIndex: this.pageIndex(this.props.location.pathname),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pathname } = nextProps.location;
    if (pathname !== this.props.location.pathname) {
      this.setState({
        pageIndex: this.pageIndex(pathname)
      });
    }
  }

  pageIndex = (pathName) => {
    const paths = pathName.split('/').filter(path => path !== '');
    const current = paths[paths.length - 1];
    if (current === 'simulation') return 0;
    if (current === 'code') return 1;
    if (current === 'finish') return 2;

    return false;
  }

  renderAppends = () => this.props.store.appends.map((append, i) =>
    <div key={i}>{append}</div>
  )

  renderSteps = () => {
    const { pageIndex } = this.state;

    return (
      <Steps
        name="onboarding-steps"
        items={
          [{
            icon: 1
          }, {
            icon: 2,
          }, {
            icon: 3
          },
          ]}
        checkedIcon={<Icon style={styles.icon} name="check" />}
        style={{ maxWidth: '460px', marginBottom: '42px', textAlign: 'center' }}
        activeItem={pageIndex}
        color="#0080FF"
        size={2}
        spacing={6}
      />);
  }


  renderStepsMobile = () => {
    const { pageIndex } = this.state;
    const { finished } = this.props.store.prospect;

    const steps = [
      <p style={styles.label}>{__('STEP %s OF %s : ', 1, 3)}<span>{__('Sign Up').toUpperCase()}</span></p>,
      <p style={styles.label}>{__('STEP %s OF %s : ', 2, 3)}<span>{__('Validate code').toUpperCase()}</span></p>,
      <p style={styles.label}>{__('STEP %s OF %s : ', 3, 3)}<span>{__('Video').toUpperCase()}</span></p>,
    ];

    return (
      pageIndex === false ? null : steps[finished ? pageIndex + 1 : pageIndex]
    );
  }

  renderHeader = () => {
    const { app } = this.props.store;
    const { router, location } = this.props;
    const { pageIndex } = this.state;

    return (
      <div style={{ width: '100%', maxWidth: '460px' }}>
        <Container style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
          {(location.pathname.replace(/[/]+/g, '') === 'trial' || location.pathname === '/trial/finish' || location.pathname === '/simulation/code')
            ? null : <Icon name="angle left" circular size="large" style={{ left: '10px', position: 'absolute', cursor: 'pointer' }} onClick={() => { router.goBack(); }} />}
          <Image
            src={utils.asset('/logo/ClassApp-LOGO-SIMBOLO_azul.png')}
            size="mini"
          />
          {
            app.env !== 'production' ?
              <div style={{ right: '10px', position: 'absolute' }}>
                <Label color={utils.envColor(app.env)} as="a" href={app.url}>{app.env}</Label>
              </div> : null
          }
        </Container>
        {pageIndex !== false &&
          <div style={{ width: '100%', marginBottom: 16 }}>
            {this.isMobile() ? this.renderStepsMobile() : this.renderSteps()}
          </div>
        }
      </div>
    );
  }

  render() {
    return (
      <Sidebar.Pushable id="ProspectNode">
        <NoJavascript />
        <Sidebar animation="overlay" width="wide" visible={false} />
        <Sidebar.Pusher style={{ height: '100%', padding: 0 }} className={this.isMobile() ? 'md' : ''}>
          <div style={{ height: '100%', overflowY: 'auto', backgroundColor: 'white' }}>
            <Grid stackable style={{ margin: 0 }}>
              <Grid.Column stretched style={{ paddingBottom: '0px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'inherit',
                    position: 'relative',
                    alignItems: 'center'
                  }}
                >
                  {this.renderHeader()}
                  <div style={{ width: '100%' }}>
                    {this.props.children}
                  </div>
                </div>
              </Grid.Column>
            </Grid>
          </div>
        </Sidebar.Pusher>
        <Snackbar />
        {this.renderAppends()}
      </Sidebar.Pushable>
    );
  }
}