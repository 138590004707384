/* eslint-disable no-extra-boolean-cast */
import React from 'react';
import { inject, observer } from 'mobx-react';

import TermsAndPrivacy from '../../components/TermsAndPrivacy';

import { __ } from '../../i18n';

const styles = {
  form: {
    maxWidth: '800px',
    paddingBottom: '70px',
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
  }
};

@inject('store') @observer
export default class DemoTerms extends React.Component {
  static fetchData({ store }) {
    store.app.title = __('Terms');
  }

  render() {
    return (<div id="DemoTerms" style={styles.form}>
      <TermsAndPrivacy />
    </div>);
  }
}
